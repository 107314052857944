<template>
  <div v-if="isLegal" id="app">
    <Vuedal />
    <div id="loader-line" :class="{ active: isLoading }" />
    <div v-if="showAC" class="age-confirmation-wrapper">
      <div class="age-confirmation">
        <ModalHeader />
        <AgeConfirmation :confirm-age="confirmAge" />
      </div>
    </div>
    <div style="height: 100vh;" v-if="showConstructor">
      <router-view name="customizer" />
    </div>
    <div v-else-if="!showAC" style="min-height: 100vh; display: flex; flex-direction: column;">
      <Header :links="headerLinks" />
      <Breadcrumbs />
      <div class="grow">
        <router-view />
      </div>
      <Footer :links="footerLinks" />
    </div>
  </div>
  <Fallback v-else />
</template>

<script>
/* eslint-disable no-unused-vars */

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import AgeConfirmation from 'Components/AgeConfirmation';
import { Bus as VuedalsBus, Component as Vuedal } from 'vuedals';
import Fallback from 'Components/Fallback';
import ModalHeader from 'Components/ModalHeader';
import Breadcrumbs from 'Components/Breadcrumbs';
import { createElement } from 'Lib/Utils';

const commonLinks = [
  { to: '/vaporizers', title: 'Vaporizers' },
  { to: '/packaging-and-accessories', title: 'Packaging and Accessories' },
  { to: '/our-services', title: 'Our services' },
  { to: '/how-it-works', title: 'How it works' },
  { href: 'https://vaporfly.com/blog', title: 'Blog', tag: 'a' },
  { to: '/about-us', title: 'About Us' },
];

const EXT_SCRIPTS = [
  {
    id: 'gtm-js',
    src: '//www.googletagmanager.com/gtag/js?id=UA-150653712-6',
    attributes: { async: '' },
    timeout: 5000,
  },
  { id: 'hs-script-loader', src: '//js-na1.hs-scripts.com/14563871.js', attributes: { async: '' }, timeout: 5000 },
];

export default {
  components: {
    AgeConfirmation,
    ModalHeader,
    Breadcrumbs,
    Fallback,
    Footer,
    Header,
    Vuedal,
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    showConstructor() {
      return this.$route.path.startsWith('/constructor');
    },
    showAC() {
      if ('__PRERENDER_INJECTED' in window) {
        return !window.__PRERENDER_INJECTED.isLegal;
      }
      return this.$store.getters.showAgeConfirmation;
    },
  },
  methods: {
    // showAgeConfirmation() {
    //   VuedalsBus.$emit('new', {
    //     name: 'age-confirmation',
    //     component: AgeConfirmation,
    //     props: { confirmAge: this.confirmAge },
    //     size: 'md',
    //     escapable: false,
    //     closeOnBackdrop: false,
    //     dismissable: false,
    //     title: false,
    //     header: {
    //       component: ModalHeader,
    //     },
    //   });
    // },
    confirmAge(state) {
      this.$store.dispatch('setLegal', !!state);
      if (state) {
        VuedalsBus.$emit('close');
      } else {
        this.isLegal = false;
      }
    },
  },
  data() {
    return {
      isLegal: true,
      footerLinks: [
        ...commonLinks,
        { to: '/contact-us', title: 'Contact Us' },
        { to: '/privacy-policy', title: 'Privacy Policy' },
        { to: '/faq', title: 'F.A.Q.' },
        { to: '/register', title: 'Sign Up' },
        { to: '/login', title: 'Log In' },
      ],
      headerLinks: commonLinks,
    };
  },
  created() {
    this.$http.interceptors.response.use(undefined, err => {
      return new Promise(() => {
        if (err && err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout');
        }
        throw err;
      });
    });
    this.$store.subscribe(mutation => {
      if (mutation.type === 'logout') {
        if (!this.$route.meta.isPrivate && this.$route.path !== '/login') {
          this.$router.push('/login');
        }
      }
    });
    this.$api.getPagesCollection({ itemsPerPage: 100 }).then(data => {
      this.$vfPages.setPages(data);
      this.$vfPages.setMetaFor(this.$route.path);
    });
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$api.getUserProfile().then(data => {
        this.$store.dispatch('setUser', data);
      });
    }
    setTimeout(() => {
      for (const item of EXT_SCRIPTS) {
        setTimeout(() => {
          if (!document.getElementById(item.id)) {
            createElement('script', { id: item.id, src: item.src, ...item.attrs }, document.head);
          }
        }, item.timeout);
      }
      setTimeout(() => {
        document.body.classList.remove('loading');
      }, 0);
    }, 0);
  },
};
</script>

<style type="text/css" scoped lang="scss">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.grow {
  flex-grow: 1;
}

#loader-line {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;

  &.active {
    $dash-width: 5%;
    @keyframes line-loader {
      0% {
        left: 0 - $dash-width;
      }
      50% {
        left: 100%;
      }
      100% {
        left: 0 - $dash-width;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: $dash-width;
      height: 3px;
      background: #96c22e;
      left: 0 - $dash-width;
      top: 0;
      animation: line-loader ease-in-out 6s infinite;
    }
  }
}
</style>
